import { Injectable } from '@angular/core';
import { PlatformIsBrowserService } from '../../platform-is-browser/platform-is-browser.service';
import LocalStorage from '../local-storage';

@Injectable()
export class LocalStorageService {

  private storage: Storage;

  constructor(private platformIsBrowserService: PlatformIsBrowserService) {
    const isBrowser = this.platformIsBrowserService.exec();
    this.storage = isBrowser ? globalThis.localStorage: new LocalStorage();
  }

  [name: string]: any;

  length: number;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    return this.storage.setItem(key, value);
  }
}
