import { SharedModule } from '../../shared/shared.module';
import { AppRoutingModule } from '../../app-routing.module';
import { SectionComponent } from './section.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionSaveComponent } from './section-save/section-save.component';
import { SectionListComponent } from './section-list/section-list.component';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SectionComponent,
    SectionSaveComponent,
    SectionListComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    PaginationModule.forRoot(),
    CarouselModule.forRoot(),
	RouterModule
  ]
})
export class SectionModule { }
