<div class="item-save scale-up-center">
  <div class="header">
    <h1 class="title">
      Seções
    </h1>
    <button type="button" class="close float-right" aria-label="Close"
      routerLink="['/cardapio']">
      <img src="assets/images/close-black.svg" alt="">
    </button>
  </div>

  <ng-template #deleteDialog>
    <div class="modal-body text-center delete-dialog">
      <p>Deseja excluir a seção?</p>
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-outline-secondary btn-block" (click)="decline()">No</button>
        </div>
        <div class="col">
          <button type="button" class="btn btn-danger btn-block" (click)="confirm()">Yes</button>
        </div>
      </div>
    </div>
  </ng-template>

  <form class="form-inline my-2 my-lg-0"
    style="margin-right: -10px; padding-bottom: 8px;">
    <div class="input-group input-group-sm mb-3 w-100">
      <input type="text" class="form-control form-control-sm"
        placeholder="Pesquisar" aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        [(ngModel)]="search"
        (keyup)="handleSearchFilterChange()"
        (change)="handleSearchFilterChange()"
        name="search">
      <div class="input-group-append">
        <span class="input-group-text search-clear" id="basic-addon2"
          (click)="resetInputSearch()">
          <img src="assets/images/close-black.svg" alt="">
        </span>
      </div>
    </div>
  </form>

  <ng-template #load>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="item" *ngFor="let section of sections.results">
    <app-section-card
	[image]="section.image"
	[name]="section.alias ? section.alias : section.section_translation_pt_br.name">
	</app-section-card>
    <div class="actions">
      <img src="assets/images/edit.svg" alt="editar seção"
        (click)="navigateTo('/gerenciar/secao/editar/'+section.id)">
      <img [src]="section.active ? 'assets/images/enabled.svg' : 'assets/images/disabled.svg'"
        alt="" (click)="active(section.id, !section.active, load)">
      <img src="assets/images/delete.svg" alt="deletar seção"
        (click)="showDeleteDialog(deleteDialog, section.id)">
    </div>
  </div>

  <div class="row" *ngIf="sections.count > 6">
    <div class="col-xs-12 col-12">
      <div class="d-flex justify-content-center">
        <pagination [totalItems]="sections.count" [rotate]="'true'"
          [maxSize]="5" [itemsPerPage]="'6'"
          (pageChanged)="pageChanged($event)"
          firstText="Primeira"
          lastText="Última"
          nextText="&raquo;"
          previousText="&laquo;">
        </pagination>
      </div>
    </div>
  </div>

</div>
<div class="shadow">
</div>
