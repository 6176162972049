import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token/token.interceptor';
import { ServerStateInterceptor } from './server-state/server-state.interceptor';
import { BrowserStateInterceptor } from './browser-state/browser-state.interceptor';

@NgModule({
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ServerStateInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
	],
})
export class InterceptorsModule {}
