import { Section } from './../../models/section';
import { createAction, props } from "@ngrx/store";

export enum ScrollActionTypes {
	SetSections = "[Menu Scroll] Set Sections",
	SetSection = "[Menu Scroll] Set Section",
	LoadAllSections = "[Menu Scroll] Load All Sections",
}

export const setSections = createAction(
	ScrollActionTypes.SetSections,
	props<{ sections: Section[] }>()
);

export const setSection = createAction(
	ScrollActionTypes.SetSection,
	props<{ section: Section }>()
);

export const loadAllSections = createAction(
	ScrollActionTypes.LoadAllSections
);