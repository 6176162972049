<div class="container">
  <div class="row">
    <div class="col-4">
      <img [src]="image" class="card-image">
    </div>
    <div class="col-8">
      <div class="content">
        <span class="title">{{ name }}</span>
      </div>
    </div>
  </div>
</div>
