<div class="container-fluid p-10">
  <!-- <h1 class="title">
    Opção do Cardápio
  </h1> -->

  <div class="d-flex justify-content-center"
    *ngIf="isLoading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>


  <div class="card-deck" *ngIf="!isLoading">
    <app-item-card (click)="showDetailItem(detailItem, $event)"
      *ngFor="let item of items.results"
      [item]="item" [large]="'true'">
      <ng-template #detailItem>
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/close.svg" alt="">
          </button>
        </div>
        <div class="modal-body">
          <img [src]="item.image" class="item-image">
          <div class="content">
            <span class="title">
              {{ item.name }}
            </span>
            <small *ngIf="item.code">
              Código {{ item.code }}
            </small>
            <small class="mt-1" *ngIf="item.display_price">
              {{ item.display_price }}
            </small>
            <p class="description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </ng-template>
    </app-item-card>
  </div>





  <!-- <div class="row">
    <div class="col-12 col-md-12 col-lg-6"
      *ngFor="let item of items.results">
      <app-item-card (click)="showDetailItem(detailItem, $event)"
        [item]="item" [large]="'true'"></app-item-card>
      <ng-template #detailItem>
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/close.svg" alt="">
          </button>
        </div>
        <div class="modal-body">
          <img [src]="item.image"
            class="item-image">
          <div class="content">
            <span class="title">
            {{ item.name }}
            </span>
            <small *ngIf="item.code">
              Código {{ item.code }}
            </small>
            <p class="description">
            {{ item.description }}
            </p>
          </div>
        </div>
      </ng-template>
    </div> -->
    <div class="row" style="width: 100%; margin-top: 30px;"
      *ngIf="items.count > 6 && !isLoading">
      <div class="col-xs-12 col-12">
        <div class="d-flex justify-content-center items-pagination">
          <pagination
            [totalItems]="items.count" [rotate]="'true'"
            [(ngModel)]="currentPage"
            [maxSize]="4"
            [itemsPerPage]="6"
            [boundaryLinks]="'true'"
            (pageChanged)="pageChanged($event)"
            firstText="&lsaquo;"
            lastText="&rsaquo;"
            nextText="&raquo;"
            previousText="&laquo;">
          </pagination>
        </div>
      </div>
    </div>
</div>
