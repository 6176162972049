import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: "app-hamburger-menu",
  templateUrl: "./hamburger-menu.component.html",
  styleUrls: ["./hamburger-menu.component.scss"]
})
export class HamburgerMenuComponent implements OnInit {
  @Input() ariaExpanded = false;

  constructor() {}

  ngOnInit() {}
}
