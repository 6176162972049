import { Injectable } from "@angular/core";
import { FormGroup } from '@angular/forms';

@Injectable()
export class PasswordValidator {

    constructor() { }

    matchPasswords(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmation: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }
}