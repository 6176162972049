import { Section } from './../../models/section';
import { Action, createReducer, on } from "@ngrx/store";
import * as ScrollActions from "../actions/scroll";

export interface State {
    sections: Section[]
}

export const initialState: State = {
	sections: [],
};

const scrollReducer = createReducer(
	initialState,
	on(ScrollActions.setSections, (state, { sections }) => ({ ...state, sections })),
	on(ScrollActions.setSection, (state, { section }) => ({
		...state,
		sections: [
			...state.sections.filter(e => e.id !== section.id),
			{
				...state.sections.find(e => e.id === section.id),
				...section,
			}
		].slice().sort((a, b) => a.position > b.position ? 1 : -1)
	})),
);

export function reducer(state: State | undefined, action: Action) {
	return scrollReducer(state, action);
}

export const getSections = (state) => state.sections.slice().sort((a, b) => a.position > b.position ? 1 : -1);