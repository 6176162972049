<section id="banner" [ngClass]="{ 'no-store': !store || !section }">
  <app-navbar></app-navbar>
  <!-- <img src="assets/images/background-home.jpg" class="background"> -->
  <!-- <div class="container d-flex justify-content-center flex-column">
    <div class="highlights" *ngIf="items.results.length > 0">
      <swiper class="swiper-container" fxFlex="auto" [config]="config">
        <div
          class="swiper-slide d-flex justify-content-center"
          *ngFor="let item of items.results"
        >
          <div class="card" style="width: 18rem">
            <img
              class="card-img-top"
              [src]="item.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <p class="card-text">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </div> -->
</section>

<div class="container mt-3">
  <form class="container form-inline my-2 my-lg-0 search" *ngIf="store">
    <div class="input-group input-group-md mb-3 w-100">
      <input
        type="text"
        class="form-control form-control-md text-dark"
        style="border-color: #d6b46a"
        placeholder="Pesquisar"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        [(ngModel)]="search"
        (keyup)="handleSearchFilterChange()"
        (change)="handleSearchFilterChange()"
        name="search"
      />
      <div class="input-group-append">
        <span
          class="input-group-text search-clear"
          id="basic-addon2"
          (click)="resetInputSearch()"
        >
          <img src="assets/images/close-black.svg" alt="" />
        </span>
      </div>
    </div>
  </form>

  <app-section-choice
    [section]="section"
    [store]="store"
    *ngIf="store && section && !search"
  >
  </app-section-choice>

  <div class="container mt-3 mb-5">
    <app-items-menu
      [section]="section.id"
      [searchFilter]="searchFilter"
      *ngIf="store && search"
    >
    </app-items-menu>
  </div>
</div>
