import { Store } from "./../../models/store";
import { createAction, props } from "@ngrx/store";
import { StoreGroup } from "src/app/models/store_group";

export enum StoreActionTypes {
	SetStore = "[Menu Store] Set Store",
	GetStore = "[Menu Store] Get Store",
	FailedGetStore = "[Menu Store] Failed Get Store",
	SuccessWhenGettingStore = "[Menu Store] Success When Getting Store",
	SetStoreGroup = "[Menu Store] Set Store Group",
}

export const setStore = createAction(
	StoreActionTypes.SetStore,
	props<{ id: number }>()
);

export const getStore = createAction(
	StoreActionTypes.GetStore,
	props<{ id: number }>()
);

export const failedGetStore = createAction(
	StoreActionTypes.FailedGetStore,
	props<{ err: any }>()
);

export const successWhenGettingStore = createAction(
	StoreActionTypes.SuccessWhenGettingStore,
	props<{ store: Store }>()
);

export const setStoreGroup = createAction(
	StoreActionTypes.SetStoreGroup,
	props<{ storeGroup: StoreGroup }>()
);
