import { ApiService } from '../../../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, NgZone, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit {

  modalRef: BsModalRef;
  message: string;
  items = {
    next: null,
    previous: null,
    results: [],
    count: 0,
  };
  lastPage = 1;
  idToDelete = null;

  search = "";
  private searchFilter: Subject<string> = new Subject<string>();

  constructor(
    private zone: NgZone, private router: Router, private route: ActivatedRoute,
    private modalService: BsModalService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.loadItems();
    this.searchItems();
  }

  ngOnChanges() {
    this.loadItems();
  }

  async loadItems(url?) {
    await this.apiService.getItemList(url)
      .subscribe(
        (response: any) => {
          this.items = response;
        }
      )
  }

  navigateTo(url) {
    this.zone.run(() => this.router.navigate([url], { relativeTo: this.route }));
  }

  showDeleteDialog(template: TemplateRef<any>, id) {
    this.idToDelete = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.message = 'Confirmed!';
    if (this.idToDelete)
      this.apiService.deleteItem(this.idToDelete)
        .subscribe(
          () => {
            this.idToDelete = null;
            this.loadItems();
            this.router.navigate(['/gerenciar/item/listar']);
            this.modalRef.hide();
          }
        )
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }

  pageChanged(event) {
    if (event.page > this.lastPage &&
      this.items.next)
      this.loadItems(this.items.next);
    else if (this.items.previous)
      this.loadItems(this.items.previous);

    this.lastPage = event.page;
  }

  active(id, active, template: TemplateRef<any>) {
    let modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.apiService.activeItem(id, active)
      .subscribe(
        () => {
          this.loadItems();
          modalRef.hide();
        }
      )
  }

  resetInputSearch(){
    if(this.search){
      this.search = "";
      this.loadItems();
    }
  }

  handleSearchFilterChange() {
    this.searchFilter.next(this.search);
  }

  private searchItems(){
    this.searchFilter
        .pipe(
          debounceTime(300),
          switchMap(value => this.apiService.searchItems(value))
        ).subscribe(
          response => this.items = response
        );
  }
}
