import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  returnToMenu(){
    this.router.navigate(['/cardapio']);
  }

}
