<div class="item-save scale-up-center">
  <div class="header">
    <h1 class="title">
      Cadastro de Seção
    </h1>
    <button type="button" class="close float-right" aria-label="Close"
      (click)="close()">
      <img [src]="getImageUrl()" alt="">
    </button>
  </div>
  <ng-template #message>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" *ngIf="submitSuccess === null">
          <span class="sr-only">Carregando...</span>
        </div>
      </div>
      <div class="alert" role="alert" *ngIf="submitSuccess !== null"
        [ngClass]="{'alert-danger': !submitSuccess, 'alert-success': submitSuccess}">
        <h4 class="alert-heading">
          {{ submitSuccess ? "Tudo certo!" : "Encontramos um problema!" }}
        </h4>
        <p>
          {{
            submitSuccess ? "A seção foi salva com sucesso!" : "Por favor, tente novamente!"
          }}
        </p>
      </div>
    </div>
    <div class="modal-footer" *ngIf="submitSuccess === false || submitSuccess === true">
      <button type="button" class="btn btn-primary btn-block" (click)="modalRef.hide()"
        [routerLink]="['/gerenciar/secao/listar']">OK</button>
    </div>
  </ng-template>
  <carousel [noWrap]="'true'">
    <slide *ngFor="let qrcode of section.qrcodes">
      <div class="slide-title">
        <p>{{ qrcode.store }}</p>
        <a [href]="qrcode.url" target="_blank">
          {{ qrcode.url }}
        </a>
      </div>
	  <a
	  [href]="qrcode.qrcode"
	  target='_blank'>
		<img [src]="qrcode.qrcode" alt="first slide"
			style="display: block; width: 100%;">
	  </a>
    </slide>
  </carousel>
  <form [formGroup]="sectionForm" (ngSubmit)="onSubmit(message)" >
    <div class="form-groupo">
      <label for="image" class="input-image">
        <img [src]="inputImage">
      </label>
      <input type="file" accept="image/png, image/jpeg, image/jpg" id="image"
        formControlName="image"
        (change)="onFileChanged($event)">
      <small id="passwordHelpBlock" class="form-text text-muted">
        Clique na imagem para alterá-la.
      </small>
    </div>
    <div class="form-group">
      <label for="name">Nome da Seção</label>
      <input type="text" class="form-control form-control-sm" id="name" formControlName="name"
        [ngClass]="{'is-invalid': controlIsInvalid('name'), 'is-valid': controlIsValid('name')}" name="name">
      <div *ngIf="controlIsInvalid('name')" class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="alias">Alias</label>
      <input type="text" class="form-control form-control-sm" id="alias" formControlName="alias"
        [ngClass]="{'is-invalid': controlIsInvalid('alias'), 'is-valid': controlIsValid('alias')}" name="alias">
      <div *ngIf="controlIsInvalid('alias')" class="invalid-feedback">
        {{ sectionForm.get('alias').hasError('alreadyExist') ? "Alias já existe" : "Campo incorreto!" }}
      </div>
    </div>
    <div class="form-group">
      <label for="items">Selecione os itens</label>
      <tag-input formControlName="items"
        id="items"
        [placeholder]="'Digite um nome de item'"
        [onTextChangeDebounce]="500"
        [secondaryPlaceholder]="'Pesquisar por item'"
        [onlyFromAutocomplete]="true"
        [ripple]="false"
        [identifyBy]="'id'"
        [displayBy]="'display'"
        name="items">
        <tag-input-dropdown [autocompleteObservable]="requestAutocompleteItems"
          [minimumTextLength]="0">
          <ng-template let-item="item" let-index="index">
            {{ item.display }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input>
      <div *ngIf="controlIsInvalid('items')" class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="stores">Selecione os lojas</label>
      <tag-input formControlName="stores" id="stores" [placeholder]="'Digite um nome de loja'"
        [onTextChangeDebounce]="500" [secondaryPlaceholder]="'Pesquisar por loja'" [onlyFromAutocomplete]="true"
        [ripple]="false" [identifyBy]="'id'" [displayBy]="'name'" name="stores">
        <tag-input-dropdown [autocompleteObservable]="requestAutocompleteStoreItems" [minimumTextLength]="0">
          <ng-template let-item="item" let-index="index">
            {{ item.display }}
          </ng-template>
        </tag-input-dropdown>
      </tag-input>
      <div *ngIf="controlIsInvalid('stores')" class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="true" id="active"
        formControlName="active" name="active">
      <label class="form-check-label" for="active">
        Ativo
      </label>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-outline-secondary btn-block"
          (click)="close()">
          Cancelar
        </button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-primary float-right btn-block"
          [disabled]="!sectionForm.valid">Salvar</button></div>
    </div>
  </form>
</div>
<div class="shadow">
</div>
