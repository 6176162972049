import { Item } from "./../../models/item";
import { List } from "./../../models/list";
import { createAction, props } from "@ngrx/store";

export enum ItemsActionTypes {
	ResetState = "[Menu Item] Reset State",
	GetItemsFromSection = "[Menu Item] Get Items From Section",
	FailedGetItems = "[Menu Item] Failed Get Items",
	SuccessWhenGettingItems = "[Menu Item] Success When Getting Items",

	GetNextItemsPage = "[Menu Item] Get Next Items Page",
	FailedGetNextItemsPage = "[Menu Item] Failed Get Next Items Page",
	SuccessWhenGettingNextItemsPage = "[Menu Item] Success When Getting Next Items Page",

	SearchItems = "[Menu Item] Search Items",
	SuccessSearchItems = "[Menu Item] Success Search Items",

	UpdateLuanguage = "[Menu Item] Update Luanguage",
	UpdateLanguageWithoutSearchItems = "[Menu Item] Update Language Without Search Items",
}

export const ResetState = createAction(
	ItemsActionTypes.ResetState,
);

export const getItemsFromSection = createAction(
	ItemsActionTypes.GetItemsFromSection,
	props<{ section: any }>()
);

export const failedGetItems = createAction(
	ItemsActionTypes.FailedGetItems,
	props<{ err: any }>()
);

export const successWhenGettingItems = createAction(
	ItemsActionTypes.SuccessWhenGettingItems,
	props<{ items: List<Item> }>()
);

export const getNextItemsPage = createAction(
	ItemsActionTypes.GetNextItemsPage
);

export const successWhenGettingNextItemsPage = createAction(
	ItemsActionTypes.SuccessWhenGettingNextItemsPage,
	props<{ items: List<Item> }>()
);

export const failedGetNextItemsPage = createAction(
	ItemsActionTypes.FailedGetNextItemsPage,
	props<{ err: any }>()
);

export const searchItems = createAction(
	ItemsActionTypes.SearchItems,
	props<{ search: string }>()
);

export const successSearchItems = createAction(
	ItemsActionTypes.SuccessSearchItems,
	props<{ items: List<Item> }>()
);

export const updateLanguage = createAction(
	ItemsActionTypes.UpdateLuanguage,
	props<{ language: string }>()
);

export const updateLanguageWithoutSearchItems = createAction(
	ItemsActionTypes.UpdateLanguageWithoutSearchItems,
	props<{ language: string }>()
);
