import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent implements OnInit {

  @Input() item = {
	id: null,
    name: '',
    alias: null,
    image: null,
    description: '',
    code: null,
    active: null,
    display_price: null,
  }

  @Input() large = false;
  @Input() useAlias = false;

  @Output() active = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  doDelete() {
    this.delete.emit();
  }

  doActive() {
    this.active.emit();
  }

  doEdit() {
    this.edit.emit();
  }

}
