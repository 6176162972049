import { AppRoutingModule } from '../../app-routing.module';
import { ItemComponent } from './item.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemSaveComponent } from './item-save/item-save.component';
import { ItemListComponent } from './item-list/item-list.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@NgModule({
  declarations: [
    ItemComponent,
    ItemSaveComponent,
    ItemListComponent,
  ],
  exports: [
    ItemComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    PaginationModule.forRoot(),
    CarouselModule.forRoot()
  ]
})
export class ItemModule { }
