import { ApiService } from '../../api.service';
import { Injectable } from '@angular/core';
import { HttpRequest,
         HttpHandler,
         HttpEvent,
         HttpInterceptor,
         HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../auth/services/auth.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { select, Store as NGRXStore } from "@ngrx/store";
import * as fromRoot from "src/app/menu/reducers";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthService,
                private apiService: ApiService,
                private router: Router,
				private store: NGRXStore<fromRoot.State>,
	) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        if(this.apiService.getToken()) {
          let access_token = this.apiService.getToken();
          request = this.addToken(request, access_token);
        }
		this.store.pipe(select(fromRoot.getItemsLanguage)).subscribe(
			language => {
				const hasParams = request.url.includes("?");
				request = request.clone({
					url: request.url + `${hasParams ? '&': '?'}language=${language}`
				});
			}
		);
        return next.handle(request)
            .pipe(
                catchError(error => {
                    if(error instanceof HttpErrorResponse && error.status === 401) {
                        return this.handle401Error(request, next);
                    } else {
                        return throwError(error);
                    }
                })
            );
    }

    private addToken(request: HttpRequest<any>, token: string) {
      return request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      if(!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);

          return this.authService.refreshToken().pipe(
              switchMap((token: any) => {
                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(token);
                  return next.handle(this.addToken(request, token.access_token));
              }),
              catchError(error => {
                  this.isRefreshing = false;
                  this.authService.logout();
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/entrar'])
                  );
                  return throwError(error);
              })
            );
      } else {
          return this.refreshTokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(token => {
                  return next.handle(this.addToken(request, token.access_token));
              })
          );
      }
  }
}
