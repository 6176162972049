import { ApiService } from './../../api.service';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Login } from '../models/login';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage-service/local-storage-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
	  private apiService: ApiService,
	  private localStorageService: LocalStorageService
) { }

  authUser(login: Login) {
    return this.apiService.authUser(login)
      .pipe(
        switchMap(
          (user: any) => {
            this.localStorageService.setItem('user', JSON.stringify(user));
            return this.createToken({ username: user.username, password: login.password});
          }
        )
      )
  }

  createToken(login: Login) {
    return this.apiService.createToken(login);
  }
}
