import { AliasValidator } from '../../../shared/validators/alias';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/api.service';

export enum Mode {
  CREATE = 1,
  UPDATE = 2
}

@Component({
  selector: 'app-section-save',
  templateUrl: './section-save.component.html',
  styleUrls: ['./section-save.component.scss']
})
export class SectionSaveComponent implements OnInit {

  mode: Mode = Mode.CREATE;

  section$: Observable<string>;
  section = {
    alias: null,
    qrcodes: []
  };

  inputImage: string | ArrayBuffer = "assets/images/no-image.svg";
  image: any;

  id: string;

  sectionForm: FormGroup = new FormGroup({});
  modalRef: BsModalRef;
  submitSuccess: boolean | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private modalService: BsModalService,
    private aliasValidator: AliasValidator) {
      this.buildForm({
        image: null,
        name: null,
        active: true,
        items: null,
        stores: null,
        alias: null
      });
    }

  public requestAutocompleteItems = (text: string): Observable<any> => {
    return this.apiService.searchItem(text);
  }

  public requestAutocompleteStoreItems = (text: string): Observable<any> => {
    return this.apiService.searchStore(text);
  }

  ngOnInit(): void {
    this.getSectionId();
  }

  getSectionId(){
    this.section$ = this.route.paramMap
      .pipe(map(paramMap => {
        let id = paramMap.get("id");
        if (id) {
          this.mode = Mode.UPDATE;
          this.id = id;
        }
        return id;
      }));

    this.section$.subscribe(
      (response: any) => {
        if(response)
          this.loadSection();
      }
    );
  }

  loadSection() {
    this.apiService.getSection(this.id)
      .subscribe(
        (response: any) => {
          this.section = response;
          this.updateForm(response);
        },
        () => this.router.navigate(['/gerenciar/secao/listar'])
      )
  }

  close() {
    let url = this.mode === Mode.CREATE ? '/cardapio' : '/gerenciar/secao/listar';
    this.router.navigate([url]);
  }

  getImageUrl() {
    let image = this.mode === Mode.UPDATE ? 'back.svg' : 'close-black.svg';
    return `assets/images/${image}`;
  }

  private buildForm(section) {
    this.sectionForm = this.formBuilder.group({
      image: [section.image, []],
      name: [section.name, [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4),
      ]],
      alias: [ section.alias, [
        Validators.minLength(4),
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/)
      ], this.aliasValidator.validateAliasExists('section').bind(this)],
      active: [section.active, []],
      items: [section.items, []],
      stores: [section.stores, [
        Validators.required,
      ]],
    });
  }

  private updateForm(section) {
    this.inputImage = section.image;
    this.sectionForm.get('name').setValue(section.section_translation_pt_br);
    this.sectionForm.get('active').setValue(section.active);
    this.sectionForm.get('alias').clearAsyncValidators();
    this.sectionForm.get('alias').setAsyncValidators(
      [this.aliasValidator.validateAliasExists('section', undefined, section.alias).bind(this)]
    )
    this.sectionForm.get('alias').setValue(section.alias);
    let stores = [];
    if(section.stores)
      section.stores.forEach(element => {
        stores.push(
          {
            display: element.name,
            id: element.id,
            name: element.name,
            value: element.id
          }
        )
      });
    this.sectionForm.get('stores').setValue(stores);

    let items = [];
    if (section.items)
      section.items.forEach(element => {
        items.push(
          {
            display: element.alias || element.name,
            id: element.id,
            name: element.alias || element.name,
            value: element.id
          }
        )
      });
    this.sectionForm.get('items').setValue(items);
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.inputImage = reader.result;
    }

    let formData = new FormData();
    formData.append('image', file);
    this.image = formData;
  }

  controlIsInvalid(control: any) {
    return this.sectionForm.get(control).invalid &&
      (this.sectionForm.get(control).dirty ||
      this.sectionForm.get(control).touched);
  }

  controlIsValid(control: any) {
    return this.sectionForm.get(control).valid &&
      (this.sectionForm.get(control).dirty ||
      this.sectionForm.get(control).touched);
  }

  buildRequestData() {
    let items = [];
    if(this.sectionForm.value.items)
      this.sectionForm.value.items.forEach(element => {
        items.push(element.id);
      });

    let stores = [];
    this.sectionForm.value.stores.forEach(element => {
      stores.push(element.id);
    });

    let data = {
      name: this.sectionForm.value.name,
      active: this.sectionForm.value.active,
      alias: this.sectionForm.value.alias,
      store_ids: stores
    }

    if (items) data['item_ids'] = items;

    return data;
  }

  onSubmit(messageTemplate: TemplateRef<any>) {
    if (this.sectionForm.invalid) return;

    this.modalRef = this.modalService.show(messageTemplate,
      {
        backdrop: false,
        ignoreBackdropClick: true
      });

    let data = this.buildRequestData();
    this.getAction(data, this.image)
      .subscribe(
        () => this.submitSuccess = true,
        () => this.submitSuccess = false
      )
  }

  private getAction(data, image: FormData) {
    if (this.mode == Mode.UPDATE)
      return this.apiService.updateSection(this.id, data, image);

    return this.apiService.createSection(data, image);
  }
}
