import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class ServerStateInterceptor implements HttpInterceptor {

  	constructor(
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const SECTION_KEY = makeStateKey(request.url);

		return next.handle(request).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					!this.transferState.hasKey(SECTION_KEY) &&
					this.transferState.set(SECTION_KEY, event.body);
				}
			})
		);
	}

}
