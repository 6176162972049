import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { KeyValidator } from './../shared/validators/key';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from './../api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  modalRef: BsModalRef;
  user: any = {};
  submitSuccess: boolean | null = null;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private keyValidator: KeyValidator,
    private modalService: BsModalService
  ) {
    this.buildForm(
      {
        first_name: null,
        last_name: null,
        username: null,
        email: null
      }
    )
  }

  ngOnInit() {
    this.loadUser();
  }

  ngOnChanges(){
    this.loadUser();
  }

  private async loadUser(){
    await this.apiService.getMe()
      .subscribe(
        (response) => {
          this.user = response;
          this.user['firstName'] = response['first_name'];
          this.user['lastName'] = response['last_name'];
          this.buildForm(response);
        }
      )
  }

  private buildForm(user) {
    this.profileForm = this.formBuilder.group({
      firstName: [user.first_name, [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4),
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/),
      ]],
      lastName: [user.last_name, [Validators.required]],
      username: [user.username, [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4),
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/),
      ], this.keyValidator.validateKeyExists(undefined, user.username).bind(this)],
      email: [user.email, [
        Validators.required,
        Validators.pattern(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1}/)
      ], this.keyValidator.validateKeyExists(undefined, user.email).bind(this)],
      password: ['', [
        Validators.minLength(6),
      ]]
    });
  }

  controlIsInvalid(control: any) {
    return this.profileForm.get(control).invalid &&
      (this.profileForm.get(control).dirty ||
       this.profileForm.get(control).touched);
  }

  controlIsValid(control: any) {
    return this.profileForm.get(control).valid &&
      (this.profileForm.get(control).dirty ||
       this.profileForm.get(control).touched);
  }

  buildRequestData() {
    let data = {};
    data = this.updateUser(data, 'firstName', 'first_name');
    data = this.updateUser(data, 'lastName', 'last_name');
    data = this.updateUser(data, 'username');
    data = this.updateUser(data, 'email');

    if(this.profileForm.value.password)
      data['password'] = this.profileForm.value.password;

    return data;
  }

  updateUser(user, property, replaceTo?){
    if (this.profileForm.value[property] !== this.user[property])
      user[replaceTo ? replaceTo : property] = this.profileForm.value[property];

    return user;
  }

  onSubmit(messageTemplate: TemplateRef<any>) {
    if (this.profileForm.invalid) return;

    this.modalRef = this.modalService.show(messageTemplate,
      {
        backdrop: false,
        ignoreBackdropClick: true
      });

    let data = this.buildRequestData();
    this.apiService.updateUser(this.user.id, data)
      .subscribe(
        () => this.submitSuccess = true,
	    () => this.submitSuccess = false
      )
  }

  isUpdated(){
    let newUser = this.buildRequestData();
    return Object.keys(newUser).length > 0;
  }

}
