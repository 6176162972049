import { ApiService } from './../api.service';
import { AuthService } from './../auth/services/auth.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  errorMessage = "Não foi possível se logar! Por favor, verifique suas credenciais e se seu cadastro já foi aprovado.";
  submitSuccess = null;

  loginForm: FormGroup;
  modalRef: BsModalRef;
  modalPasswordResetRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/cardapio']);
    }
  }

  private buildForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
      ]]
    });
  }

  private buildRequestData(){
    return {
      username: this.loginForm.value.email,
      password: this.loginForm.value.password
    }
  }

  controlIsInvalid(control: any) {
    return this.loginForm.get(control).invalid &&
      (this.loginForm.get(control).dirty ||
        this.loginForm.get(control).touched);
  }

  controlIsValid(control: any) {
    return this.loginForm.get(control).valid &&
      (this.loginForm.get(control).dirty ||
        this.loginForm.get(control).touched);
  }

  showPasswordReset(template: TemplateRef<any>){
    this.modalPasswordResetRef = this.modalService.show(template);
  }

  onResetPassword(email: HTMLInputElement){
    this.modalPasswordResetRef.hide();
    if (this.validateEmailResetPassword(email.value))
      this.apiService.resetPassword(email.value)
        .subscribe()
  }

  validateEmailResetPassword(email){
    return email.match(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1}/);
  }

  onSubmit(messageTemplate: TemplateRef<any>) {
    if (this.loginForm.invalid) return;

    this.modalRef = this.modalService.show(messageTemplate,
      {
        backdrop: false,
        ignoreBackdropClick: true
      });

    let data = this.buildRequestData();
    this.authService.login(data)
      .subscribe(
        response => {
          this.submitSuccess = response;
          if (response) {
            this.router.navigate(['/cardapio']);
          }
        },
        err => {
          this.submitSuccess = false;
        },
        () => {
          this.loginForm.reset();
          this.modalRef.hide();
        }
      )
  }
}
