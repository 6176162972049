import { AuthGuard } from "./auth/guards/auth.guard";
import { SingUpComponent } from "./sing-up/sing-up.component";
import { LoginComponent } from "./login/login.component";
import { ProfileComponent } from "./profile/profile.component";

import { ItemListComponent } from "./management/item/item-list/item-list.component";

import { SectionComponent } from "./management/section/section.component";
import { SectionSaveComponent } from "./management/section/section-save/section-save.component";
import { SectionListComponent } from "./management/section/section-list/section-list.component";
import { ManagementComponent } from "./management/management.component";

import { FilterComponent } from "./filter/filter.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ItemComponent } from "./management/item/item.component";
import { ItemSaveComponent } from "./management/item/item-save/item-save.component";

const routes: Routes = [
  /*{
    path: "cardapio/:store",
    loadChildren: () =>
      import("./menu/menu.module").then((m) => m.MenuModule),
  },*/
  {
    path: "",
    redirectTo: "/cardapio",
    pathMatch: "full"
  },
  {
    path: "entrar",
    component: LoginComponent
  },
  {
    path: "cadastro",
    component: SingUpComponent
  },
  {
    path: "cardapio",
    component: FilterComponent
  },
  {
	path: "cardapio/:store",
    loadChildren: () =>
      import("./catalog/catalog.module").then((m) => m.CatalogModule)
  },
  {
    path: "cardapio/:store/:catalog",
    loadChildren: () =>
      import("./menu/menu.module").then((m) => m.MenuModule),
  },
  {
    path: "perfil",
    component: ProfileComponent
  },
  {
    path: "gerenciar",
    component: ManagementComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      { path: "", redirectTo: "/cardapio", pathMatch: "full" },
      {
        path: "item",
        component: ItemComponent,
        children: [
          { path: "", redirectTo: "novo", pathMatch: "full" },
          {
            path: "novo",
            component: ItemSaveComponent
          },
          {
            path: "editar/:id",
            component: ItemSaveComponent
          },
          {
            path: "listar",
            component: ItemListComponent
          }
        ]
      },
      {
        path: "secao",
        component: SectionComponent,
        children: [
          { path: "", redirectTo: "novo", pathMatch: "full" },
          {
            path: "editar/:id",
            component: SectionSaveComponent
          },
          {
            path: "novo",
            component: SectionSaveComponent
          },
          {
            path: "listar",
            component: SectionListComponent
          }
        ]
      }
    ]
  },
  {
    path: "**",
    redirectTo: "/cardapio"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
