import { Login } from "./auth/models/login";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DEFAULT_REQUEST } from "./auth/models/request";
import { mergeMap, map } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { LocalStorageService } from "./services/local-storage/local-storage-service/local-storage-service";
import { environment } from "../environments/environment";

const API_URL = environment.domainHost;

@Injectable({
	providedIn: "root",
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private localStorageService: LocalStorageService
	) {}

	private getAttributeOfToken(attribute) {
		let token = JSON.parse(
			this.localStorageService.getItem("token") || "{}"
		);
		if (token.hasOwnProperty(attribute)) return token[attribute];
		return null;
	}

	getRefreshToken() {
		return this.getAttributeOfToken("refresh_token");
	}

	getToken() {
		return this.getAttributeOfToken("access_token");
	}

	revokeToken() {
		let headers = new HttpHeaders({
			"Content-Type": DEFAULT_REQUEST.contentType,
		});
		let data = {
			token: this.getToken(),
			client_id: DEFAULT_REQUEST.clientId,
			client_secret: DEFAULT_REQUEST.clientSecret,
		};

		return this.http.post<any>(`${API_URL}/o/revoke_token/`, data, {
			headers: headers,
		});
	}

	refreshToken() {
		let headers = new HttpHeaders({
			"Content-Type": DEFAULT_REQUEST.contentType,
		});

		let data = {
			client_id: DEFAULT_REQUEST.clientId,
			client_secret: DEFAULT_REQUEST.clientSecret,
			grant_type: "refresh_token",
			refresh_token: this.getRefreshToken(),
		};

		return this.http.post<any>(`${API_URL}/o/token/`, data, {
			headers: headers,
		});
	}

	authUser(login: Login) {
		return this.http.post(`${API_URL}/api/v1/auth/`, {
			username: login.username,
			password: login.password,
		});
	}

	createToken(login: Login) {
		let headers = new HttpHeaders({
			"Content-Type": DEFAULT_REQUEST.contentType,
		});

		let data = {
			client_id: DEFAULT_REQUEST.clientId,
			client_secret: DEFAULT_REQUEST.clientSecret,
			grant_type: "password",
			username: login.username,
			password: login.password,
		};

		return this.http.post(`${API_URL}/o/token/`, data, {
			headers: headers,
		});
	}

	userExist(value) {
		return this.http.get(`${API_URL}/api/v1/user/exist/${value}/`);
	}

	aliasExist(value, feature) {
		return this.http.get(`${API_URL}/api/v1/${feature}/exist/${value}/`);
	}

	createUser(user: any) {
		return this.http.post(`${API_URL}/api/v1/user/create`, user);
	}

	resetPassword(email) {
		let data = {
			email: email,
		};
		return this.http.post(
			`${API_URL}/api/v1/accounts/password-reset/`,
			data
		);
	}

	getStoreList(uri?: string) {
		uri = uri ? uri : `${API_URL}/api/v1/store/list`;
		return this.http.get(uri);
	}

	getStoreListAll(uri?: string) {
		return this.getStoreList(uri).pipe(
			mergeMap(
				(response: any) => {
					if (response.next)
						return this.getStoreListAll(response.next);

					return of(null);
				},
				(last: any, current: any) => {
					if (current) {
						last.next = current.next;
						last.results.push(...current.results);
					}

					return last;
				}
			)
		);
	}

	getSectionList(uri?: string, storeId?: number): Observable<any> {
		uri = uri ? uri : `${API_URL}/api/v1/section/list`;
		if (storeId) uri = `${uri}?store_id=${storeId}`;

		return this.http.get(uri);
	}

	getSection(id) {
		let uri = `${API_URL}/api/v1/section/${id}/`;

		return this.http.get(uri);
	}

	getSectionListAll(uri?: string, storeId?: number): Observable<any> {
		return this.getSectionList(uri, storeId).pipe(
			mergeMap(
				(response: any) => {
					if (response.next)
						return this.getSectionListAll(response.next);

					return of(null);
				},
				(last: any, current: any) => {
					if (current) {
						last.next = current.next;
						last.results.push(...current.results);
					}

					return last;
				}
			)
		);
	}

	getItemList(uri?: string) {
		uri = uri ? uri : `${API_URL}/api/v1/item/list`;
		return this.http.get(uri);
	}

	getItemListFilter(section) {
		let uri = `${API_URL}/api/v1/item/list?section_id=${section}`;
		return this.http.get(uri);
	}

	getMe() {
		let uri = `${API_URL}/api/v1/user/me`;
		return this.http.get(uri);
	}

	updateUser(id, user) {
		let uri = `${API_URL}/api/v1/user/${id}/`;
		return this.http.put(uri, user);
	}

	searchItem(name) {
		const uri = `${API_URL}/api/v1/item/list?search=${name}`;
		return this.http.get<any>(uri).pipe(
			map((items) =>
				items.results.map((item) => {
					let currentName = item.name;
					if (item.alias && item.alias.includes(name)) {
						currentName = item.alias;
					}
					return {
						display: currentName,
						id: item.id,
						name: currentName,
						value: item.id,
					};
				})
			)
		);
	}

	searchItems(search, storeId?) {
		let uri = `${API_URL}/api/v1/item/list?search=${search}`;
		if (storeId) {
			uri = `${uri}&sections__stores__id__in=${storeId}`;
		}

		return this.http.get<any>(uri);
	}

	searchSection(name: string) {
		const uri = `${API_URL}/api/v1/section/list?search=${name}`;
		return this.http.get<any>(uri).pipe(
			map((items) =>
				items.results.map((item) => {
					let currentName = item.section_translation_pt_br;
					if (item.alias && item.alias.includes(name)) {
						currentName = item.alias;
					}

					return {
						display: currentName,
						id: item.id,
						name: currentName,
						value: item.id,
					};
				})
			)
		);
	}

	searchSections(name) {
		const uri = `${API_URL}/api/v1/section/list?search=${name}`;
		return this.http.get<any>(uri);
	}

	searchStore(name) {
		const uri = `${API_URL}/api/v1/store/list?search=${name}`;
		return this.http.get<any>(uri).pipe(
			map((items) =>
				items.results.map((item) => {
					return {
						display: item.name,
						id: item.id,
						name: item.name,
						value: item.id,
					};
				})
			)
		);
	}

	createItem(item: any, image: FormData) {
		return this.http.post(`${API_URL}/api/v1/item/create`, item).pipe(
			mergeMap(
				(responseCreate: any) => {
					return this.uploadImageItem(responseCreate, image);
				},
				(_: any, responseImage: any) => {}
			)
		);
	}

	updateItem(id: number, item: any, image: FormData) {
		return this.http.put(`${API_URL}/api/v1/item/${id}/`, item).pipe(
			mergeMap(
				(responseCreate: any) => {
					return this.uploadImageItem(responseCreate, image);
				},
				(_: any, responseImage: any) => {}
			)
		);
	}

	uploadImageItem(item, image: FormData) {
		if (image)
			return this.http.post(
				`${API_URL}/api/v1/item/uploadimage/${item.id}/`,
				image
			);

		return of(item);
	}

	createSection(section: any, image: FormData) {
		return this.http.post(`${API_URL}/api/v1/section/create`, section).pipe(
			mergeMap(
				(responseCreate: any) => {
					return this.uploadImageSection(responseCreate, image);
				},
				(_: any, responseImage: any) => {}
			)
		);
	}

	deleteSection(id) {
		return this.http.delete(`${API_URL}/api/v1/section/${id}/`);
	}

	activeSection(id, active) {
		let data = {
			active: active,
		};
		return this.http.put(`${API_URL}/api/v1/section/${id}/`, data);
	}

	updateSection(id: string, section: any, image: FormData) {
		return this.http.put(`${API_URL}/api/v1/section/${id}/`, section).pipe(
			mergeMap(
				(responseCreate: any) => {
					return this.uploadImageSection(responseCreate, image);
				},
				(_: any, responseImage: any) => {}
			)
		);
	}

	uploadImageSection(section, image: FormData) {
		if (image)
			return this.http.post(
				`${API_URL}/api/v1/section/uploadimage/${section.id}/`,
				image
			);

		return of(section);
	}

	activeItem(id, active) {
		let data = {
			active: active,
		};
		return this.http.put(`${API_URL}/api/v1/item/${id}/`, data);
	}

	deleteItem(id) {
		return this.http.delete(`${API_URL}/api/v1/item/${id}/`);
	}

	getItem(id) {
		let uri = `${API_URL}/api/v1/item/${id}/`;

		return this.http.get(uri);
	}

	getItemListSpotlight(url?, spotlight?) {
		if (!spotlight) spotlight = true;

		let uri = `${API_URL}/api/v1/item/list?spotlight=${spotlight}&active=true`;
		return this.http.get(uri);
	}

	getAllItemListSpotlight(spotlight, url?) {
		return this.getItemListSpotlight(url, spotlight).pipe(
			mergeMap(
				(response: any) => {
					if (response.next)
						return this.getAllItemListSpotlight(
							spotlight,
							response.next
						);

					return of(null);
				},
				(last: any, current: any) => {
					if (current) {
						last.next = current.next;
						last.results.push(...current.results);
					}

					return last;
				}
			)
		);
	}

	getStore(id) {
		let uri = `${API_URL}/api/v1/store/${id}/`;

		return this.http.get(uri);
	}
}
