import { ApiService } from 'src/app/api.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, catchError } from "rxjs/operators";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})
export class FilterComponent implements OnInit {

  store$: Observable<string>;
  store;
  storeId;

  section$: Observable<string>;
  section;

  @Input() isOpenPopover = true;

  @Input() validateFilters = true;

  search = "";
  private searchFilter: Subject<any> = new Subject<any>();
  items = {
    results: []
  }

  public innerWidth: any;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: true
  };

  @Input() notLoadStore = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    this.store$ = this.route.paramMap
      .pipe(
        map(paramMap => paramMap.get("store")),
        mergeMap(
          (id: any) => {
            if (!id || !Number.isInteger(parseInt(id)))
              return throwError('is not a id');

            this.storeId = Number(id);
            this.search = "";
            this.handleSearchFilterChange();
            return this.apiService.getStore(id);
          },
          (store: any, _: any) => store
        ),
        catchError(error => {
          if (this.validateFilters)
            this.router.navigate(['/cardapio']);
          return throwError(error);
        })
      );

    this.section$ = this.route.paramMap
      .pipe(
        map(paramMap => paramMap.get("section")),
        mergeMap(
          (id: any) => {
            if (!id || !Number.isInteger(parseInt(id)))
              return throwError('is not a id');

            return this.apiService.getSection(id);
          },
          (store: any, _: any) => store
        ),
        catchError(error => {
          if (this.validateFilters)
            this.router.navigate(['/cardapio']);
          return throwError(error);
        })
      );
  }

  ngOnInit(): void {
    this.search = "";
    this.store$.subscribe((data) => this.store = data, _ => { });
    this.section$.subscribe((data) => this.section = data, _ => { });
  }

  ngOnChanges() {
    this.isOpenPopover = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = (event.target as Window).innerWidth;
    this.config.slidesPerView = this.innerWidth > 1024 ? 3 : 1;
  }

  resetInputSearch() {
    if (this.search) {
      this.search = "";
      this.handleSearchFilterChange();
    }
  }

  handleSearchFilterChange() {
    this.searchFilter.next({storeId: this.storeId, search: this.search});
  }

  private async loadHighlights() {
    await this.apiService.getAllItemListSpotlight(true).
      subscribe(
        (response: any) => this.items = response
      )
  }
}
