import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/platform-browser';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

	constructor(private transferState: TransferState,) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (request.method !== 'GET') {
			return next.handle(request);
		}

		const storedResponse: string = this.transferState.get(makeStateKey(request.url), '');
		if (storedResponse) {
			const response = new HttpResponse({ body: storedResponse, status: 200 });
			return of(response);
		}

		return next.handle(request);
	}
}
