import { ApiService } from '../../../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, NgZone, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent implements OnInit {

  modalRef: BsModalRef;
  message: string;
  sections = {
    next: null,
    previous: null,
    results: [],
    count: 0,
  };
  lastPage = 1;
  idToDelete = null;

  search = "";
  private searchFilter: Subject<string> = new Subject<string>();


  constructor(
    private zone: NgZone, private router: Router, private route: ActivatedRoute,
    private modalService: BsModalService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.loadSections();
    this.searchSections();
  }

  ngOnChanges(){
    this.loadSections();
  }

  async loadSections(url?: any){
    await this.apiService.getSectionList(url)
      .subscribe(
        (response: any) => {
          this.sections = response;
        }
      )
  }

  navigateTo(url) {
    this.zone.run(() => this.router.navigate([url], { relativeTo: this.route }));
  }

  showDeleteDialog(template: TemplateRef<any>, id) {
    this.idToDelete = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  confirm(): void {
    this.message = 'Confirmed!';
    if (this.idToDelete)
      this.apiService.deleteSection(this.idToDelete)
        .subscribe(
          () => {
            this.idToDelete = null;
            this.loadSections();
            this.router.navigate(['/gerenciar/secao/listar']);
            this.modalRef.hide();
          }
        )
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }

  pageChanged(event) {
    if (event.page > this.lastPage &&
        this.sections.next)
      this.loadSections(this.sections.next);
    else if(this.sections.previous)
      this.loadSections(this.sections.previous);

    this.lastPage = event.page;
  }


  active(id, active, template: TemplateRef<any>) {
    let modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.apiService.activeSection(id, active)
      .subscribe(
        () => {
          this.loadSections();
          modalRef.hide();
        }
      )
  }

  resetInputSearch() {
    if (this.search) {
      this.search = "";
      this.loadSections();
    }
  }

  handleSearchFilterChange() {
    this.searchFilter.next(this.search);
  }

  private searchSections() {
    this.searchFilter
      .pipe(
        debounceTime(300),
        switchMap(value => this.apiService.searchSections(value))
      ).subscribe(
        response => this.sections = response
      );
  }

}
