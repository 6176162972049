import { ApiService } from './../api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  username = "Usuário";
  email = "";

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.apiService.getMe()
      .subscribe(
        (response) => {
          this.username = this.getUsername(response);
          this.email = response['email'];
        }
      )
  }

  getUsername(user) {
    if(user['first_name']) return user['first_name'];

    return user['username'];
  }

}
