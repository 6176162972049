import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AliasValidator } from 'src/app/shared/validators/alias';

export enum Mode {
  CREATE = 1,
  UPDATE = 2
}

@Component({
  selector: 'app-item-save',
  templateUrl: './item-save.component.html',
  styleUrls: ['./item-save.component.scss']
})
export class ItemSaveComponent implements OnInit {

  inputImage: string | ArrayBuffer = "assets/images/no-image.svg";
  image: any;

  mode: Mode = Mode.CREATE;

  item$: Observable<string>;
  item;
  id: number;

  itemForm: FormGroup;
  modalRef: BsModalRef;
  submitSuccess: boolean | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private modalService: BsModalService,
    private aliasValidator: AliasValidator) {
      this.buildForm();
    }

  public requestAutocompleteItems = (text: string): Observable<any> => {
    return this.apiService.searchSection(text);
  }

  ngOnInit(): void {
    this.item$ = this.route.paramMap
      .pipe(map(({ params }: any) => {
		let id = params.id;
        if (id) {
          this.mode = Mode.UPDATE;
          this.id = id;
         }
        return id;
      }));
    this.item$.subscribe(
      (response: any) => {
        if(response)
          this.loadItem();
      }
    );
  }

  loadItem() {
    this.apiService.getItem(this.id)
      .subscribe(
        (response: any) => {
          this.item = response;
          this.updateForm(response);
        },
        () => this.router.navigate(['/gerenciar/item/listar'])
      )
  }

  private updateForm(item) {
    this.inputImage = item.image;
    this.itemForm.get('name').setValue(item.name);
    this.itemForm.get('alias').setValue(item.alias);
    this.itemForm.get('description').setValue(item.description);
    this.itemForm.get('active').setValue(item.active);
    this.itemForm.get('spotlight').setValue(item.spotlight);
    this.itemForm.get('code').setValue(item.code);
    this.itemForm.get('price').setValue(this.maskIt(item.display_price || '', "R$ "));
    this.itemForm.get('alias').setAsyncValidators(
      [this.aliasValidator.validateAliasExists('item', undefined, item.alias).bind(this)]
    )
    this.itemForm.get('alias').setValue(item.alias);

    let sections = [];
    if (item.sections) {
		item.sections.forEach((element: any) => {
		  sections.push(
			{
			  display: element.alias || element.display_name,
			  id: element.id,
			  name: element.alias || element.display_name,
			  value: element.id
			}
		  )
		});
	}
    this.itemForm.get('sections')?.setValue(sections);
  }

  close(){
    let url = this.mode === Mode.CREATE ? '/cardapio' : '/gerenciar/item/listar';
    this.router.navigate([url]);
  }

  getImageUrl(){
    let image = this.mode === Mode.UPDATE ? 'back.svg' : 'close-black.svg';
    return `assets/images/${image}`;
  }

  private buildForm() {
    this.itemForm = this.formBuilder.group({
      image: ['', []],
      name: ['', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4),
      ]],
      alias: [ '', [
        Validators.minLength(4),
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/)
      ], this.aliasValidator.validateAliasExists('item').bind(this) ],
      price: ['', [

      ]],
      code: ['', [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^(?!0+$)\d+$/)
      ]],
      description: ['', [
        Validators.required,
        Validators.maxLength(255),
      ]],
      spotlight: [false, []],
      active: [true, []],
      sections: ['', [
        Validators.required,
      ]],
    });
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    let size = file.size / 1024 / 1024;
    if (size >= 2.4){
      this.itemForm.get('image').setErrors({ invalidSize: true });
    }


    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.inputImage = reader.result;
    }

    let formData = new FormData();
    formData.append('image', file);
    this.image = formData;
  }

  controlIsInvalid(control: any) {
    return this.itemForm.get(control).invalid &&
      (this.itemForm.get(control).dirty ||
      this.itemForm.get(control).touched);
  }

  controlIsValid(control: any) {
    return this.itemForm.get(control).valid &&
      (this.itemForm.get(control).dirty ||
      this.itemForm.get(control).touched);
  }

  buildRequestData() {
	  //Mode.UPDATE
	let request = {
		alias: this.itemForm.value.alias,
		spotlight: this.itemForm.value.spotlight,
		active: this.itemForm.value.active,
		code: this.itemForm.value.code,
		price: this.removeMask(this.itemForm.value.price, "R$ "),
		section_ids: this.itemForm.value.sections.map((e: any) => e.id)
	}
	if(this.mode == Mode.UPDATE) {
		request = {
			...request, ... {
				name_pt_br: this.itemForm.value.name,
				description_pt_br: this.itemForm.value.description
			}
		}
		return request;
	}
	return {
		...request,
		...{
			name: this.itemForm.value.name,
			description: this.itemForm.value.description
		}
	};
  }

  onSubmit(messageTemplate: TemplateRef<any>) {
    if (this.itemForm.invalid) return;

    this.modalRef = this.modalService.show(messageTemplate,
      {
        backdrop: false,
        ignoreBackdropClick: true
      });

    let data = this.buildRequestData();
    this.getAction(data, this.image)
      .subscribe(
        () => this.submitSuccess = true,
        () => this.submitSuccess = false
      )
  }

  private getAction(data, image: FormData){
    if(this.mode == Mode.UPDATE)
      return this.apiService.updateItem(this.id, data, image);

    return this.apiService.createItem(data, image);
  }

  onKeyPressPrice(event){
    event.preventDefault();
    let value = event.target.value;
    value = value.replace(/^R\$+/i, '');
    if (event.key.match(/^\d+$/) && value.length <= 11){
      event.target.value = this.maskIt(value + event.key, "R$ ");
    } else if (event.key === "Backspace")  {
      if (value.length > 0) { value = value.substring(0, value.length - 1); }
      event.target.value = this.maskIt(value, "R$ ");
    }

    this.itemForm.get('price').setValue(event.target.value);
  }

  private maskIt(v, currency=""){
    v = v.replace(/\D/g,"");
    v = new String(Number(v));
    var len = v.length;
    if (1== len)
      v = v.replace(/(\d)/,"0,0$1");
    else if (2 == len)
      v = v.replace(/(\d)/,"0,$1");
    else if (len > 2) {
      v = v.replace(/(\d{2})$/,',$1');
      if (len > 5) {
          var x = len - 5
          ,er = new RegExp('(\\d{'+x+'})(\\d)');
          v = v.replace(er,'$1.$2');
      }
      if (len > 8) {
        var x = len - 8
        ,er = new RegExp('(\\d{'+x+'})(\\d)');
        v = v.replace(er,'$1.$2');
      }
    }
    return currency + v;
  }

  private removeMask(value, currency){
    let newValue = value.replace(currency, "");
    newValue = newValue.replace(".", "");
    newValue = newValue.replace(",", ".");

    return Number(newValue);
  }


}
