import { ApiService } from '../api.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

interface HandleNameChangeInterface {
  target: HTMLInputElement;
}

@Component({
  selector: 'app-section-choice',
  templateUrl: './section-choice.component.html',
  styleUrls: ['./section-choice.component.scss']
})
export class SectionChoiceComponent implements OnInit, OnChanges {

  sections = {
    next: null,
    results: []
  };

  @Input('section') selectedsection;
  @Input('store') selectedStore;
  public updateItems: Subject<number> = new Subject<number>();

  constructor(
    private apiService: ApiService,
    private route: Router
  ) { }

  ngOnInit() {
    this.loadSections();
  }

  ngOnChanges() {
    this.loadSections();
  }

  loadSections() {
    this.apiService.getSectionListAll(undefined, this.selectedStore)
      .subscribe(
        (response: any) => {
          this.sections = response;
          this.sections.results = [...response.results];
        }
      )
  }

  onSelectSection(section, event: HandleNameChangeInterface) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName === 'a' || event.target.classList.contains('panel-body')) { return; }

    if (event.target.classList.contains("accordion-toggle") &&
        !JSON.parse(event.target.attributes.getNamedItem('aria-expanded').value)) {
      return;
    }

    this.updateItems.next(section);
  }
}
