import { Store } from "./../../models/store";
import { StoreGroup } from "src/app/models/store_group";
import { Action, createReducer, on } from "@ngrx/store";
import * as StoreActions from "../actions/store";

export interface State extends Store {
	loading: boolean;
	error: any;
	group: StoreGroup;
}

export const initialState: State = {
	id: null,
	address: null,
	name: null,
	phone_number: null,
	latitude: null,
	longitude: null,
	loading: false,
	error: null,
	sections: [],
	active: null,
	group: null,
};

const storeReducer = createReducer(
	initialState,
	on(StoreActions.setStore, (state, { id }) => ({ ...state, id })),
	on(StoreActions.getStore, (state) => ({ ...state, loading: true })),
	on(StoreActions.successWhenGettingStore, (state, { store }) => ({
		...state,
		...store,
		loading: false,
	})),
	on(StoreActions.failedGetStore, (state, err) => ({
		...state,
		loading: false,
		error: err,
	})),
	on(StoreActions.setStoreGroup, (state, { storeGroup }) => ({
		...state,
		group: storeGroup,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return storeReducer(state, action);
}

export const getStoreError = (state: State) => state.error;
export const getStoreLoading = (state: State) => state.loading;
