import { ApiService } from './../api.service';
import { PasswordValidator } from './../shared/validators/password';
import { KeyValidator } from './../shared/validators/key';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sing-up',
  templateUrl: './sing-up.component.html',
  styleUrls: ['./sing-up.component.scss']
})
export class SingUpComponent implements OnInit {

  modalRef: BsModalRef;
  singUpForm: FormGroup;
  submitSuccess: boolean | null = null;

  constructor(
    private keyValidator: KeyValidator,
    private passwordValidator: PasswordValidator,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: BsModalService
  ) {
    this.buildForm();
  }

  ngOnInit() {
  }

  private buildForm(){
    this.singUpForm = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(1),
      ]],
      lastName:  ['', [
        Validators.required,
        Validators.maxLength(255),
        Validators.minLength(1),
      ]],
      username:  ['', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(4),
        Validators.pattern(/^(?![_.@])(?!.*[*_*.*@]{2})[a-zA-Z0-9_@.]{0,101}[a-zA-Z0-9]$/),
      ], this.keyValidator.validateKeyExists().bind(this)],
      email: ['', [
        Validators.required,
        Validators.pattern(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1}/)
      ], this.keyValidator.validateKeyExists().bind(this)],
      password:  ['', [
        Validators.required,
        Validators.minLength(6),
      ]],
      password2: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
    },
    {
      validator: this.passwordValidator.matchPasswords('password', 'password2').bind(this)
    });
  }

  controlIsInvalid(control: any) {
    return this.singUpForm.get(control)?.invalid &&
      (this.singUpForm.get(control)?.dirty ||
       this.singUpForm.get(control)?.touched);
  }

  controlIsValid(control: any) {
    return this.singUpForm.get(control)?.valid &&
      (this.singUpForm.get(control)?.dirty ||
        this.singUpForm.get(control)?.touched);
  }

  buildRequestData(){
    return {
      username: this.singUpForm.value.username,
      email: this.singUpForm.value.email,
      password: this.singUpForm.value.password,
      first_name: this.singUpForm.value.firstName,
      last_name: this.singUpForm.value.lastName
    }
  }

  onSubmit(messageTemplate: TemplateRef<any>){
    if (this.singUpForm.invalid) return;

    this.modalRef = this.modalService.show(messageTemplate,
      {
        backdrop: false,
        ignoreBackdropClick: true
      });

    let data = this.buildRequestData();
    this.apiService.createUser(data)
      .subscribe(
        () => this.submitSuccess = true,
        () => this.submitSuccess = false
      )
  }


}
