<section id="login-banner">
  <app-navbar></app-navbar>
  <img src="assets/images/background-home.jpg" class="background">
  <div class="login">
    <ng-template #message
      class="modal-message">
      <div class="modal-body">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status" *ngIf="submitSuccess === null">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="submitSuccess === false || submitSuccess === true">
        <button type="button" class="btn btn-primary btn-block" (click)="modalRef.hide()"
          [routerLink]="['/entrar']">OK</button>
      </div>
    </ng-template>
    <ng-template #resetPassword>
      <div class="modal-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(message)">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="text" class="form-control form-control-sm"
              aria-describedby="emailHelp" placeholder="" required
              #resetEmailField>
          </div>
          <small class="form-text text-muted">
            Insira seu e-mail e caso você tenha um cadastro no sistema lhe enviaremos um e-mail com os próximos passos.
          </small>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-block" (click)="onResetPassword(resetEmailField)"
          >ENVIAR</button>
      </div>
    </ng-template>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit(message)">
      <div class="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" class="form-control form-control-sm" id="exampleInputEmail1"
          formControlName="email"
          [ngClass]="{'is-invalid': controlIsInvalid('email'), 'is-valid': controlIsValid('email')}"
          aria-describedby="emailHelp" placeholder="">
        <div *ngIf="controlIsInvalid('email')" class="invalid-feedback">
          Campo incorreto!
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Senha</label>
        <input type="password" class="form-control form-control-sm"
          formControlName="password"
          [ngClass]="{'is-invalid': controlIsInvalid('password'), 'is-valid': controlIsValid('password')}"
          id="exampleInputPassword1" placeholder="">
        <div *ngIf="controlIsInvalid('password')" class="invalid-feedback">
          Campo incorreto!
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" class="btn btn-sm btn-primary float-right btn-block"
            [disabled]="!loginForm.valid"
            >ENTRAR</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" class="btn float-left btn-sm btn-link"
            [routerLink]="['/cadastro']">
            Cadastre-se
          </button>
        </div>
        <div class="col">
          <button type="submit" class="btn float-right btn-sm btn-link"
            (click)="showPasswordReset(resetPassword)">
            Esqueceu sua senha?
          </button>
        </div>
      </div>
      <alert type="danger" dismissible="true"
        *ngIf="submitSuccess === false">
        {{ errorMessage  }}
      </alert>
    </form>
  </div>
</section>
