import { ActionReducerMap } from "@ngrx/store";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import * as fromStore from "./store";
import * as fromItems from "./items";
import * as fromScroll from "./scroll";
import * as fromCatalog from "./catalog";

export interface State {
	menu: MenuState;
}

export interface MenuState {
	store: fromStore.State;
	items: fromItems.State;
	scroll: fromScroll.State;
	catalog: fromCatalog.State;
}

export const reducers: ActionReducerMap<MenuState> = {
	store: fromStore.reducer,
	items: fromItems.reducer,
	scroll: fromScroll.reducer,
	catalog: fromCatalog.reducer,
};

export const menuFeatureKey = "menu";

export const getMenuFeatureState =
	createFeatureSelector<MenuState>(menuFeatureKey);

export const getStoreState = createSelector(
	getMenuFeatureState,
	(state) => state.store
);

export const getStoreStateId = createSelector(
	getMenuFeatureState,
	(state) => state.store.id
);

export const getStoreError = createSelector(
	getStoreState,
	fromStore.getStoreError
);

export const getStoreLoading = createSelector(
	getStoreState,
	fromStore.getStoreLoading
);

export const getItemsState = createSelector(
	getMenuFeatureState,
	(state) => state.items
);

export const getItemsSearch = createSelector(
	getItemsState,
	(state) => state.search
);

export const getItemsError = createSelector(
	getItemsState,
	fromItems.getItemsError
);

export const getItemsNext = createSelector(
	getItemsState,
	fromItems.getItemsNext
);

export const getItemsResults = createSelector(
	getItemsState,
	fromItems.getItemsResults
);

export const getItemsResultsFiltered = createSelector(
	getItemsState,
	fromItems.getItemsResultsFiltered
);

export const getItemsCount = createSelector(
	getItemsState,
	fromItems.getItemsCount
);

export const getItemsLoading = createSelector(
	getItemsState,
	fromItems.getItemsLoading
);

export const getItemsLanguage = createSelector(
	getItemsState,
	fromItems.getItemsLanguage
);

export const getScrollState = createSelector(
	getMenuFeatureState,
	(state) => state.scroll
);

export const getSections = createSelector(
	getScrollState,
	fromScroll.getSections
);

export const getCatalog = createSelector(
	getMenuFeatureState,
	(state) => state.catalog
);

export const getCatalogList = createSelector(
	getCatalog,
	(state) => state.catalogList
);

export const getCatalogSelected = createSelector(
	getCatalog,
	(state) => state.catalogSelected
);

export const getCatalogSelectedId = createSelector(
	getCatalog,
	(state) => state.catalogSelectedId
);

export const getCatalogShowActionSheet = createSelector(
	getCatalog,
	(state) => state.showActionSheet
);
