<nav class="navbar navbar-expand-lg navbar-dark bg-light-dark">
	<a class="navbar-brand" [routerLink]="['/filter']">
		<img src="assets/images/logo-cb.png" />
	</a>
	<div class="collapse navbar-collapse" id="navbarNavAltMarkup">
		<div class="navbar-nav"></div>
	</div>
	<img
		src="assets/images/sign-in.svg"
		alt=""
		class="login-button"
		*ngIf="!isLoggedIn()"
		[routerLink]="['/entrar']"
	/>
	<app-hamburger-menu
		*ngIf="isLoggedIn()"
		ariaExpanded="menuItensCollapsed"
		(click)="menuOnClick($event)"
	>
	</app-hamburger-menu>
	<div
		class="collapse menuItens"
		*ngIf="isLoggedIn()"
		[ngClass]="{
			show: menuItensCollapsed,
			'slide-top': menuItensCollapsed
		}"
	>
		<div class="profile bg-light-dark">
			<app-profile-card></app-profile-card>
			<app-logout-button
				class="app-logout-button"
				(click)="onLogout()"
			></app-logout-button>
		</div>
		<div class="bg-light-dark">
			<span class="item" [routerLink]="['/perfil']"
				>Visualizar Perfil</span
			>
			<span class="item" [routerLink]="['/gerenciar/item/novo']"
				>Novo Item</span
			>
			<span class="item" [routerLink]="['/gerenciar/secao/novo']"
				>Nova Seção</span
			>
			<span class="item" [routerLink]="['/gerenciar/item/listar']"
				>Gerenciar Items</span
			>
			<span class="item" [routerLink]="['/gerenciar/secao/listar']"
				>Gerenciar Seções</span
			>
		</div>
	</div>
</nav>
