import { ApiService } from './../../api.service';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { LoginService } from 'src/app/auth/services/login.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage-service/local-storage-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
	private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private apiService: ApiService) { }

  login(user: { username: string, password: string }): Observable<boolean> {
    return this.loginService.authUser({ username: user.username, password: user.password })
      .pipe(
        tap((response: any) => {
          this.doLoginUser(user.username, response);
        }),
        mapTo(true),
        catchError(err => of(false))
      );
  }

  verifyPermissionLogin(): boolean {
    return JSON.parse(this.localStorageService.getItem('token') || "{}") ? true : false;
  }

  logout() {
    this.doLogoutUser();
    return this.apiService.revokeToken()
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError(error => of(false))
      )
  }

  refreshToken() {
    return this.apiService.refreshToken()
      .pipe(
        tap((response: any) => {
          this.storeToken(response);
        })
      );
  }

  isLoggedIn(){
    let token = JSON.parse(this.localStorageService.getItem('token') || "{}");
    return token && token["access_token"];
  }

  private doLoginUser(username: string, token: any) {
    this.storeToken(token);
  }

  private doLogoutUser() {
    this.clearLocalStorage();
  }

  private storeToken(token: string) {
    this.localStorageService.setItem('token', JSON.stringify(token));
  }

  private clearLocalStorage() {
    this.localStorageService.removeItem('user');
    this.localStorageService.removeItem('token');
  }

}
