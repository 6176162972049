<app-filter [routerLink]="['/cardapio']"
  [validateFilters]="false"
  [isOpenPopover]="false"></app-filter>
<div class="profile scale-up-center">
  <div class="header">
    <h1 class="title">
      Perfil
    </h1>
    <button type="button" class="close float-right" aria-label="Close"
      [routerLink]="['/cardapio']">
      <img src="assets/images/close.svg" alt="">
    </button>
  </div>
  <ng-template #message>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" *ngIf="submitSuccess === null">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="alert" role="alert" *ngIf="submitSuccess !== null"
        [ngClass]="{'alert-danger': !submitSuccess, 'alert-success': submitSuccess}">
        <h4 class="alert-heading">
          {{ submitSuccess ? "Atualizado!" : "Encontramos um problema!" }}
        </h4>
        <p>
          {{
            submitSuccess ? "Seus dados foram atualizados!" : "Por favor, tente novamente!"
          }}
        </p>
      </div>
    </div>
    <div class="modal-footer" *ngIf="submitSuccess === false || submitSuccess === true">
      <button type="button" class="btn btn-primary btn-block" (click)="modalRef.hide()"
        [routerLink]="['/perfil']">OK</button>
    </div>
  </ng-template>
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit(message)">
    <div class="form-group">
      <label for="name">Nome</label>
      <input type="text" class="form-control form-control-sm"
        id="name"
        formControlName="firstName"
        aria-describedby="emailHelp"
        [ngClass]="{'is-invalid': controlIsInvalid('firstName'), 'is-valid': controlIsValid('firstName')}"
        placeholder="">
      <div
        *ngIf="controlIsInvalid('firstName')"
        class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="lastName">Sobrenome</label>
      <input type="text" class="form-control form-control-sm"
        id="lastName"
        formControlName="lastName"
        [ngClass]="{'is-invalid': controlIsInvalid('lastName'), 'is-valid': controlIsValid('lastName')}"
        placeholder="">
      <div
        *ngIf="controlIsInvalid('lastName')"
        class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="username">Usuário</label>
      <input type="text" class="form-control form-control-sm"
        id="username"
        formControlName="username"
        [ngClass]="{'is-invalid': controlIsInvalid('username'), 'is-valid': controlIsValid('username')}"
        placeholder="">
      <div *ngIf="controlIsInvalid('username')" class="invalid-feedback">
        {{ profileForm.get('username').hasError('alreadyExist') ? "Usuário já existe" : "Campo incorreto!" }}
      </div>
    </div>
    <div class="form-group">
      <label for="email">E-mail</label>
      <input type="e-mail" class="form-control form-control-sm"
        id="email"
        formControlName="email"
        [ngClass]="{'is-invalid': controlIsInvalid('email'), 'is-valid': controlIsValid('email')}"
        >
      <div *ngIf="controlIsInvalid('email')" class="invalid-feedback">
        {{ profileForm.get('email').hasError('alreadyExist') ? "Email já existe" : "Campo incorreto!" }}
      </div>
    </div>
    <div class="form-group">
      <label for="password">Nova Senha</label>
      <input type="password" id="password" class="form-control"
        aria-describedby="passwordHelpBlock"
        formControlName="password"
        [ngClass]="{'is-invalid': controlIsInvalid('password'), 'is-valid': controlIsValid('password')}"
        placeholder="">
      <div *ngIf="controlIsInvalid('password')" class="invalid-feedback">
        Campo incorreto!
      </div>
      <small id="passwordHelpBlock" class="form-text text-muted">
        Digite uma nova senha, caso queria alterá-la.
      </small>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-outline-secondary btn-block"
          [routerLink]="['/cardapio']">
          Cancelar
        </button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-primary float-right btn-block"
          [disabled]="!profileForm.valid || !isUpdated()">Salvar</button>
      </div>
    </div>
    <br>
  </form>
</div>
<div class="shadow">
</div>
