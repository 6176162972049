import { AuthService } from './../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {

  menuItensCollapsed = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {}

  menuOnClick(event) {
    this.menuItensCollapsed = !this.menuItensCollapsed;
  }

  isLoggedIn(){
    return this.authService.isLoggedIn();
  }

  onLogout(){
    this.authService.logout().subscribe(
      (logout) => {
        if(logout) this.router.navigate(['/cardapio']);
      }
    )
  }
}
