import { AliasValidator } from "./validators/alias";
import { HttpClientModule } from "@angular/common/http";
import { PasswordValidator } from "./validators/password";
import { KeyValidator } from "./validators/key";
import { ProfileComponent } from "./../profile/profile.component";
import { SectionCardComponent } from "../section-card/section-card.component";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TagInputModule } from "ngx-chips";

import { NavbarComponent } from "../navbar/navbar.component";
import { HamburgerMenuComponent } from "../hamburger-menu/hamburger-menu.component";
import { LogoutButtonComponent } from "../logout-button/logout-button.component";
import { ProfileCardComponent } from "../profile-card/profile-card.component";
import { SelectStoreComponent } from "../select-store/select-store.component";
import { SectionChoiceComponent } from "../section-choice/section-choice.component";
import { ItemsMenuComponent } from "../items-menu/items-menu.component";
import { ItemCardComponent } from "../item-card/item-card.component";
import { FilterComponent } from "../filter/filter.component";

import { PaginationModule } from "ngx-bootstrap/pagination";
import { AccordionModule } from "ngx-bootstrap/accordion";

import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { DirectivesModule } from "./directives/directives.module";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: "horizontal",
	slidesPerView: "auto",
};

@NgModule({
	declarations: [
		NavbarComponent,
		HamburgerMenuComponent,
		LogoutButtonComponent,
		ProfileCardComponent,
		SelectStoreComponent,
		SectionChoiceComponent,
		ItemsMenuComponent,
		ItemCardComponent,
		FilterComponent,
		SectionCardComponent,
		ProfileComponent,
	],
	exports: [
		NavbarComponent,
		HamburgerMenuComponent,
		LogoutButtonComponent,
		ProfileCardComponent,
		SelectStoreComponent,
		SectionChoiceComponent,
		ItemsMenuComponent,
		ItemCardComponent,
		FilterComponent,
		SectionCardComponent,
		ProfileComponent,
		ReactiveFormsModule,
		FormsModule,
		TagInputModule,
		BrowserAnimationsModule,
	],
	providers: [
		KeyValidator,
		PasswordValidator,
		AliasValidator,
		{
			provide: SWIPER_CONFIG,
			useValue: DEFAULT_SWIPER_CONFIG,
		},
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		HttpClientModule,
		TagInputModule,
		BrowserAnimationsModule,
		PaginationModule.forRoot(),
		SwiperModule,
		AccordionModule.forRoot(),
		DirectivesModule,
	],
})
export class SharedModule {}
