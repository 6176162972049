import { ApiService } from './../api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from '../interceptors/token/token.interceptor';
import { LoginService } from './services/login.service';
import { StoreModule } from '@ngrx/store';
import * as fromStore from "src/app/menu/reducers";

@NgModule({
    declarations: [
    ],
    providers: [
        AuthGuard,
        AuthService,
        LoginService,
        ApiService,
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
		StoreModule.forFeature(fromStore.menuFeatureKey, fromStore.reducers),
    ]
})
export class AuthModule {}
