<div class="card mt-3">
	<img
		class="card-img-top"
		[src]="item.image"
		alt="{{ item.name }}"
		loading="lazy"
	/>
	<div class="card-body">
		<h5 class="card-title">
			{{ useAlias && item.alias ? item.alias : item.name }}
		</h5>
		<p class="card-text code">
			<small class="text-muted">
				{{ item.code }}
			</small>
		</p>
		<p class="card-text code" *ngIf="item.display_price">
			{{ item.display_price }}
		</p>
		<p class="card-text description">
			{{ item.description }}
		</p>
		<div class="actions" *ngIf="!large">
			<img src="assets/images/delete.svg" alt="" (click)="doDelete()" />
			<img
				[src]="
					item.active
						? 'assets/images/enabled.svg'
						: 'assets/images/disabled.svg'
				"
				alt=""
				(click)="doActive()"
			/>
			<img
				src="assets/images/edit.svg"
				alt="editar item"
				(click)="doEdit()"
			/>
		</div>
	</div>
</div>
