import { ApiService } from '../api.service';
import { Component, OnInit, TemplateRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Subject, Observable } from 'rxjs';
import { debounceTime, switchMap, filter, tap } from 'rxjs/operators';

@Component({
  selector: "app-items-menu",
  templateUrl: "./items-menu.component.html",
  styleUrls: ["./items-menu.component.scss"]
})
export class ItemsMenuComponent implements OnInit, OnChanges {
  modalRef: BsModalRef;
  lastPage = 1;
  currentPage = 1;

  items = {
    next: null,
    previous: null,
    count: 0,
    results: []
  }

  @Input('section') selectedSection: number;
  @Input() load = true;
  @Input() searchFilter: Subject<string> = new Subject<string>();
  @Input() update: Subject<number> = new Subject<number>();
  isLoading = true;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService
    ) {
    }

  ngOnInit() {
    this.updateItems();
    if (!this.load) { return; }

    this.loadItems();
    this.searchItems();
  }

  ngOnChanges() {
    if (!this.load) { return; }

    this.loadItems();
    this.searchItems();
  }

  loadItems() {
    this.isLoading = true;
    this.getAction()
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.items = response;
          this.items.results = [...response.results];
        }
      )
  }

  private getAction(){
    if(this.selectedSection)
      return this.apiService.getItemListFilter(
        this.selectedSection
      );

    return this.apiService.getItemList();
  }

  showDetailItem(template: TemplateRef<any>, event) {
    this.modalRef = this.modalService.show(template);
    event.stopPropagation();
  }

  pageChanged(event) {
    let url = null;
    if (event.page > this.lastPage &&
      this.items.next)
      url = this.items.next;
    else if (this.items.previous)
      url = this.items.previous;

    if (url)
      this.apiService.getItemList(url)
        .subscribe(
          (response: any) => {
            this.items = response;
            this.items.results = [...response.results];
            this.isLoading = false;
          }
        )
    this.lastPage = event.page;
  }

  private searchItems() {
    this.isLoading = true;
    this.searchFilter.subscribe();
    this.searchFilter
      .pipe(
        debounceTime(300),
        filter((value: any) => value.storeId && value.search),
        switchMap(value => value ? this.apiService.searchItems(value.search, value.storeId) : this.getAction())
      ).subscribe(
        response => {
          this.isLoading = false;
          this.items = response;
          this.items.results = [...response.results];
        }
      );
  }

  private updateItems(){
    this.isLoading = true;
    this.update.pipe(
      filter(section => section === this.selectedSection),
      switchMap((flag: any) => {
        this.selectedSection = flag;
        return this.getAction();
      })
    )
    .subscribe(
      (response: any) => {
        this.lastPage = 1;
        this.currentPage = 1;
        this.items = response;
        this.items.results = [...response.results];
        this.isLoading = false;
      }
    )
  }
}
