import { EffectsModule } from "@ngrx/effects";
import { ApiService } from "./api.service";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";
import { ManagementModule } from "./management/management.module";
import {
	BrowserModule,
	BrowserTransferStateModule,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { ModalModule } from "ngx-bootstrap/modal";
import { AlertModule } from "ngx-bootstrap/alert";
import { PaginationModule } from "ngx-bootstrap/pagination";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { SingUpComponent } from "./sing-up/sing-up.component";
import { StoreModule } from "@ngrx/store";
import { PlatformIsBrowserService } from "./services/platform-is-browser/platform-is-browser.service";
import { LocalStorageService } from "./services/local-storage/local-storage-service/local-storage-service";
import { InterceptorsModule } from "./interceptors/interceptors.module";

@NgModule({
	declarations: [AppComponent, LoginComponent, SingUpComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: "serverApp" }),
		AppRoutingModule,
		ModalModule.forRoot(),
		AlertModule.forRoot(),
		PaginationModule.forRoot(),
		ManagementModule,
		SharedModule,
		AuthModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		BrowserTransferStateModule,
		InterceptorsModule
	],
	providers: [ApiService, PlatformIsBrowserService, LocalStorageService],
	bootstrap: [AppComponent],
})
export class AppModule {}
