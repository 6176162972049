import { NgModule } from "@angular/core";
import { LazyImgDirective } from "./lazy-img/lazy-img.directive";

@NgModule({
	declarations: [LazyImgDirective],
	exports: [LazyImgDirective],
	providers: [],
	imports: [],
})
export class DirectivesModule {}
