<div class="container mt-3 mb-5">
  <!-- <label for="" class="label-section" *ngFor="let section of sections.results"
    [ngClass]="{'active': selectedSection == section.id}" (click)="onSelectSection(section.id)">
    {{ section.name }}
  </label> -->
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [heading]="section.name"
      *ngFor="let section of sections.results"
      (click)="onSelectSection(section.id, $event)">
      <app-items-menu [section]="section.id"
        [update]="updateItems" [load]="selectedSection == section.id">
      </app-items-menu>
    </accordion-group>
  </accordion>
</div>
