import { ApiService } from './../../api.service';
import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";

import { map, debounceTime, take, catchError } from 'rxjs/operators';

@Injectable()
export class AliasValidator {

  constructor(private apiService: ApiService) { }

  validateAliasExists(feature: any, time: number = 0, key?: any) {
    return (input: FormControl) => {
      return this.apiService.aliasExist(input.value, feature)
        .pipe(
          debounceTime(time),
          take(1),
          map(res => !res || input.value == key ? null : { alreadyExist: true }),
          catchError(() => new Promise(resolve => resolve(null)))
        );
    };
  }
}
