import { Action, createReducer, on } from "@ngrx/store";
import * as CatalogActions from "../actions/catalog";
import { Catalog } from "src/app/models/catalog";

export interface State {
	catalogList: Catalog[];
	catalogSelected: Catalog;
	catalogSelectedId: string;
	showActionSheet: boolean;
}

export const initialState: State = {
	catalogList: [],
	catalogSelected: null,
	catalogSelectedId: null,
	showActionSheet: false,
};

const catalogReducer = createReducer(
	initialState,
	on(CatalogActions.UpdateCatalogList, (state, { catalogList }) => ({
		...state,
		loading: true,
		catalogList
	})),
	on(CatalogActions.SetCatalogSelected, (state, { id }) =>
	({ ...state, catalogSelectedId: id, catalogSelected: { ...state.catalogSelected, id } })),
	on(CatalogActions.UpdateCatalogSelected, (state, { catalogSelected }) =>
	({ ...state, catalogSelected, catalogSelectedId: catalogSelected.id })),
	on(CatalogActions.UpdateCatalogSelectedById, (state, { id }) =>
	({ ...state, catalogSelectedId: id })),
	on(CatalogActions.ResetState, (state) => ({ ...state, ...initialState })),
	on(CatalogActions.UpdateShowActionSheet, (state, { showActionSheet }) =>
	({ ...state, showActionSheet })),
);

export function reducer(state: State | undefined, action: Action) {
	return catalogReducer(state, action);
}

export const getCatalogList = (state: State) => state.catalogList;
export const getCatalogSelected = (state: State) => state.catalogSelected;
export const getCatalogSelectedId = (state: State) => state.catalogSelectedId;
export const getCatalogShowDialog = (state: State) => state.showActionSheet;
