<section id="sign-up-banner">
  <app-navbar></app-navbar>
  <img src="assets/images/background-home.jpg" class="background">
  <div class="sign-up">
    <ng-template #message>
      <div class="modal-body">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status"
            *ngIf="submitSuccess === null">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="alert" role="alert"
          *ngIf="submitSuccess !== null"
          [ngClass]="{'alert-danger': !submitSuccess, 'alert-success': submitSuccess}">
          <h4 class="alert-heading">
            {{ submitSuccess ? "Tudo certo!" : "Encontramos um problema!" }}
          </h4>
          <p>
            {{
              submitSuccess ? "Você receberá um e-mail de confirmação de cadastro, mas só poderá acessar o sistema após a autorização do administrador!" : "Por favor, tente novamente!"
            }}
          </p>
        </div>
      </div>
      <div class="modal-footer" *ngIf="submitSuccess === false || submitSuccess === true">
        <button type="button" class="btn btn-primary btn-block"
          (click)="modalRef.hide()"
          [routerLink]="['/entrar']">OK</button>
      </div>
    </ng-template>
    <form [formGroup]="singUpForm" (ngSubmit)="onSubmit(message)">
      <div class="form-group">
        <label for="exampleInputEmail1">Nome</label>
        <input type="text" class="form-control form-control-sm" id="firstName"
          formControlName="firstName"
          [ngClass]="{'is-invalid': controlIsInvalid('firstName'), 'is-valid': controlIsValid('firstName')}"
          placeholder="">
        <div
          *ngIf="controlIsInvalid('firstName')"
          class="invalid-feedback">
          Campo incorreto!
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Sobrenome</label>
        <input type="text" class="form-control form-control-sm" id="exampleInputEmail1"
          formControlName="lastName"
          [ngClass]="{'is-invalid': controlIsInvalid('lastName'), 'is-valid': controlIsValid('lastName')}"
          aria-describedby="emailHelp" placeholder="">
        <div *ngIf="controlIsInvalid('lastName')" class="invalid-feedback">
          Campo incorreto!
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Usuário</label>
        <input type="text" class="form-control form-control-sm" id="username"
          formControlName="username"
          [ngClass]="{'is-invalid': controlIsInvalid('username'), 'is-valid': controlIsValid('username')}"
          aria-describedby="emailHelp" placeholder="">
          <div *ngIf="controlIsInvalid('username')" class="invalid-feedback">
            {{ singUpForm.get('username').hasError('alreadyExist') ? "Usuário já existe" : "Campo incorreto!" }}
          </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" class="form-control form-control-sm" id="exampleInputEmail1"
          formControlName="email"
          [ngClass]="{'is-invalid': controlIsInvalid('email'), 'is-valid': controlIsValid('email')}"
          aria-describedby="emailHelp" placeholder="">
        <div *ngIf="controlIsInvalid('email')" class="invalid-feedback">
          {{ singUpForm.get('email').hasError('alreadyExist') ? "Email já existe" : "Campo incorreto!" }}
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Senha</label>
        <input type="password" class="form-control form-control-sm"
          formControlName="password"
          [ngClass]="{'is-invalid': controlIsInvalid('password'), 'is-valid': controlIsValid('password')}"
          id="exampleInputPassword1" placeholder="">
        <div *ngIf="controlIsInvalid('password')" class="invalid-feedback">
          Campo incorreto!
        </div>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Confirmar Senha</label>
        <input type="password" class="form-control form-control-sm"
          formControlName="password2"
          [ngClass]="{'is-invalid': controlIsInvalid('password2'), 'is-valid': controlIsValid('password2')}"
          id="exampleInputPassword1" placeholder="">
        <div *ngIf="controlIsInvalid('password2')" class="invalid-feedback">
          {{ singUpForm.get('password2').hasError('confirmation') ? "As senhas não combinam" : "Campo incorreto!" }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" class="btn btn-sm btn-primary float-right btn-block"
            [disabled]="!singUpForm.valid"
            >CADASTRAR</button>
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-sm btn-outline-secondary float-right btn-block"
            [routerLink]="['/entrar']">ENTRAR</button>
        </div>
      </div>
    </form>
  </div>
</section>
