<div class="item-save scale-up-center">
  <div class="header">
    <h1 class="title">
      Cadastro de Item
    </h1>
    <button type="button" class="close float-right" aria-label="Close"
      (click)="close()">
      <img [src]="getImageUrl()" alt="">
    </button>
  </div>
  <ng-template #message>
    <div class="modal-body">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status" *ngIf="submitSuccess === null">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="alert" role="alert" *ngIf="submitSuccess !== null"
        [ngClass]="{'alert-danger': !submitSuccess, 'alert-success': submitSuccess}">
        <h4 class="alert-heading">
          {{ submitSuccess ? "Tudo certo!" : "Encontramos um problema!" }}
        </h4>
        <p>
          {{
            submitSuccess ? "O item foi salvo com sucesso!" : "Por favor, tente novamente!"
          }}
        </p>
      </div>
    </div>
    <div class="modal-footer" *ngIf="submitSuccess === false || submitSuccess === true">
      <button type="button" class="btn btn-primary btn-block" (click)="modalRef.hide()"
        [routerLink]="['/gerenciar/item/listar']">OK</button>
    </div>
  </ng-template>
  <form [formGroup]="itemForm" (ngSubmit)="onSubmit(message)" >
    <div class="form-groupo">
      <label for="image" class="input-image">
        <img [src]="inputImage">
      </label>
      <input type="file" accept="image/png, image/jpeg, image/jpg"
        id="image"
        formControlName="image" (change)="onFileChanged($event)"
        [ngClass]="{'is-invalid': controlIsInvalid('image'), 'is-valid': controlIsValid('image')}">
      <div
        *ngIf="controlIsInvalid('image')"
        style="display: block"
        class="invalid-feedback">
        Campo incorreto! Verifique se sua imagem é maior que 2.5M.
      </div>
      <small id="passwordHelpBlock" class="form-text text-muted">
        Clique na imagem para alterá-la.
      </small>
    </div>
    <div class="form-group">
      <label for="name">Nome do Item</label>
      <input type="text" class="form-control form-control-sm"
        id="name"
        formControlName="name"
        [ngClass]="{'is-invalid': controlIsInvalid('name'), 'is-valid': controlIsValid('name')}"
        name="name">
      <div
        *ngIf="controlIsInvalid('name')"
        class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="alias">Alias</label>
      <input type="text" class="form-control form-control-sm" id="alias" formControlName="alias"
        [ngClass]="{'is-invalid': controlIsInvalid('alias'), 'is-valid': controlIsValid('alias')}" name="alias">
      <div *ngIf="controlIsInvalid('alias')" class="invalid-feedback">
        {{ itemForm.get('alias').hasError('alreadyExist') ? "Alias já existe" : "Campo incorreto!" }}
      </div>
    </div>
    <!-- <div class="form-group">
      <label for="code">Preço do Item</label>
      <input type="text" class="form-control form-control-sm"
        id="price"
        formControlName="price"
        [ngClass]="{'is-invalid': controlIsInvalid('price'), 'is-valid': controlIsValid('price')}"
        (keypress)="onKeyPressPrice($event)"
        name="price">
      <div
        *ngIf="controlIsInvalid('price')"
        class="invalid-feedback">
        Campo incorreto!
      </div>
    </div> -->
    <div class="form-group">
      <label for="code">Código do Item</label>
      <input type="text" class="form-control form-control-sm"
        id="code"
        formControlName="code"
        [ngClass]="{'is-invalid': controlIsInvalid('code'), 'is-valid': controlIsValid('code')}"
        name="code">
      <div
        *ngIf="controlIsInvalid('code')"
        class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="description">Descrição do Item</label>
      <textarea class="form-control form-control-sm" id="description" rows="3"
        formControlName="description"
        [ngClass]="{'is-invalid': controlIsInvalid('description'), 'is-valid': controlIsValid('description')}"
        name="description">
      </textarea>
      <div *ngIf="controlIsInvalid('description')" class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-group">
      <label for="exampleFormControlSelect1">Selecione uma Seção</label>
      <tag-input formControlName="sections"
                 [placeholder]="'Digite um nome de uma seção'"
                 [onTextChangeDebounce]="500"
                 [secondaryPlaceholder]="'Pesquisar por seção'"
                 [onlyFromAutocomplete]="true"
                 [ripple]="false"
                 [identifyBy]="'id'"
				 [displayBy]="'display'"
                 name="sections">
        <tag-input-dropdown
            [autocompleteObservable]="requestAutocompleteItems"
            [minimumTextLength]="0">
            <ng-template let-item="item" let-index="index">
                {{ item.display }}
            </ng-template>
        </tag-input-dropdown>
      </tag-input>
      <div *ngIf="controlIsInvalid('sections')" class="invalid-feedback">
        Campo incorreto!
      </div>
    </div>
    <div class="form-check">
      <input class="form-check-input"
        type="checkbox"
        id="spotlight"
        formControlName="spotlight"
        name="spotlight">
      <label class="form-check-label" for="spotlight">
        Destaque
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox"
        value="true" id="active"
        formControlName="active"
        name="active">
      <label class="form-check-label" for="active">
        Ativo
      </label>
    </div>
    <br>
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-outline-secondary btn-block"
          (click)="close()">
          Cancelar
        </button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-primary float-right btn-block"
          [disabled]="!itemForm.valid">Salvar</button></div>
    </div>
  </form>
</div>
<div class="shadow">
</div>
