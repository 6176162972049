import { Item } from "../../models/item";
import { List } from "../../models/list";
import { Action, createReducer, on } from "@ngrx/store";
import * as ItemsActions from "../actions/items";

export interface State extends List<Item> {
	loading: boolean;
	error: any;
	sections: any[];
	search: string;
	resultsFiltered: any[];
	language: string;
}

export const initialState: State = {
	next: null,
	previous: null,
	count: 0,
	results: [],
	resultsFiltered: [],
	loading: false,
	error: null,
	sections: [],
	search: null,
	language: 'pt-br'
};

const itemsReducer = createReducer(
	initialState,
	on(ItemsActions.ResetState, (state) => ({
		...state,
		...initialState,
		language: state.language
	})),
	on(ItemsActions.getItemsFromSection, (state, { section }) => ({
		...state,
		loading: true,
		sections: [...state.sections, section]
	})),
	on(ItemsActions.successWhenGettingItems, (state, { items }) => ({
		...state,
		count: state.count + items.count,
		next: items.next,
		results: [
			...state.results,
			...items.results
		],
		loading: false,
	})),
	on(ItemsActions.failedGetItems, (state, { err }) => ({
		...state,
		loading: false,
		error: err,
	})),
	on(ItemsActions.getNextItemsPage, (state) => ({
		...state,
		loading: true,
	})),
	on(ItemsActions.successWhenGettingNextItemsPage, (state, { items }) => ({
		...state,
		count: state.count + items.count,
		next: items.next,
		results: [...state.results, ...items.results],
		loading: false,
	})),
	on(ItemsActions.failedGetItems, (state, { err }) => ({
		...state,
		loading: false,
		error: err,
	})),
	on(ItemsActions.searchItems, (state, { search }) => ({
		...state,
		search: search,
		loading: true,
	})),
	on(ItemsActions.successSearchItems, (state, { items }) => ({
		...state,
		count: items.count,
		next: items.next,
		resultsFiltered: [...items as any],
		loading: false,
	})),
	on(ItemsActions.updateLanguage, (state, { language }) => ({
		...state,
		language,
		search: '',
		results: [],
		resultsFiltered: []
	})),
	on(ItemsActions.updateLanguageWithoutSearchItems, (state, { language }) => ({
		...state,
		language
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return itemsReducer(state, action);
}

export const getItemsError = (state: State) => state.error;
export const getItemsLoading = (state: State) => state.loading;
export const getItemsNext = (state: State) => state.next;
export const getItemsResults = (state: State) => state.results;
export const getItemsResultsFiltered = (state: State) => state.resultsFiltered;
export const getItemsCount = (state: State) => state.count;
export const getItemsLanguage = (state: State) => state.language;
export const getItemsSearch = (state: State) => state.search;
