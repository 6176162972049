import { createAction, props } from "@ngrx/store";
import { Catalog } from "src/app/models/catalog";

export enum CatalogActionTypes {
	ResetState = "[Menu Catalog] Reset State",

	UpdateCatalogList = "[Menu Catalog] Update Catalog List",
	UpdateCatalogSelected = "[Menu Catalog] Update Catalog Selected",
	UpdateCatalogSelectedById = "[Menu Catalog] Update Catalog Selected By Id",

	SetCatalogSelected = "[Menu Catalog] Set Catalog Selected",
	UpdateShowActionSheet = "[Menu Catalog] Update Show Action Sheet",
}

export const UpdateCatalogList = createAction(
	CatalogActionTypes.UpdateCatalogList,
	props<{ catalogList: Catalog[] }>()
);

export const UpdateCatalogSelected = createAction(
	CatalogActionTypes.UpdateCatalogSelected,
	props<{ catalogSelected: Catalog }>()
);

export const SetCatalogSelected = createAction(
	CatalogActionTypes.SetCatalogSelected,
	props<{ id: string }>()
);

export const UpdateCatalogSelectedById = createAction(
	CatalogActionTypes.UpdateCatalogSelectedById,
	props<{ id: string }>()
);

export const ResetState = createAction(
	CatalogActionTypes.ResetState
);

export const UpdateShowActionSheet = createAction(
	CatalogActionTypes.UpdateShowActionSheet,
	props<{ showActionSheet: boolean }>()
);
